// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require jquery_ujs
//= require jquery-ui/widgets/sortable
//= require rails_sortable
//= require bootstrap-table/bootstrap-table
import 'bootstrap'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")
global.$ = require("jquery-ui")

$(function(){
    // Plain jquery
    $('#fadeMe').fadeOut(5000);

    // jquery-ui
    const availableCities = ['Baltimore', 'New York'];
    $('#cityField').autocomplete( { source: availableCities } );
    $('#calendarField').datepicker( { dateFormat: 'yy-mm-dd' } );
})


// Import UJS so we can access the Rails.ajax method
import Rails from "@rails/ujs";

$(document).on("turbolinks:load", () => {
  $("#drivers").sortable({
    axis:'y',
    height: 25,
    connectWith: ".drivers",
    cursor: "move",
    handle: '.handle',
    containment: "parent",
    helper: 'clone',
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });
})
require("chartkick").use(require("highcharts"))

require("@rails/activestorage").start()
require("channels")
require('jquery')
require("trix")
require("@rails/actiontext")
require("@rails/ujs").start()



require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")



// require('notie/dist/notie')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)





require("trix")
require("@rails/actiontext")




$(document).on("turbolinks:load", () => {
  $("#drivers").sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
    }
  });
})



import ahoy from "ahoy.js";